<style>
  @import "/css/story.css";
</style>

<template>
    <div class="services">
        <Pedagogie></Pedagogie>
    </div>
</template>

<script>
import Pedagogie from '@/components/Services/Pedagogie.vue'

export default {
    name: 'Y-pedagogie',

    components: {
        Pedagogie
    }
}
</script>
